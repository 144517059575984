@import "../../scss/colors.scss";

.home-page {
  .steps {
    margin: 20px 0 0px 0;
    svg {
      path: {
        fill: #dc7c01 !important;
      }
    }
    h3 {
      font-size: 20px;
      margin-left: 20px;
      span {
        color: #dc7c01;
      }
    }
  }

  .top-container {
    margin-bottom: 200px;
  }

  .lower-container {
    min-height: 300px;
    background-color: $grey-background;
    border-top: 10px solid white;
    img {
      margin-top: -180px;
    }
    h2 {
      padding-top: 0;
    }
  }
}
