.abilitiesDetailPage {
  h2 {
    margin-top: 0px;
    font-size: 32px;
    font-weight: 700;
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1px solid #eeeeee;
  }
  a {
    text-decoration: none;
    color: black;
    display: block;
    font-size: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
  .state {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f3e9e0;
  }
}
