.OrganizationAdministration {
  background-color: white;
  min-height: 90vh;
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
}

h2.accordion-header {
  margin: 0;
  padding: 0;
}
