.feedback-component {
  h2.accordion-header button {
    font-size: 24px;
  }
  .accordion-body p {
    font-size: 18px;
  }
  .pr-30 {
    padding-right: 30px;
  }
}
