.orgUserRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.lastLogin {
  font-size: 10px;
  font-style: italic;
  margin-left: 40px;
}

.orgUserRow:nth-child(even) {
  background-color: #eaeaea;
}

span.last-completed {
  display: block;
  position: absolute;
  left: 300px;
  font-size: 11px;
  font-style: italic;
}
