@import "/src/scss/custom.scss";

@mixin border-for-nav-item($color) {
  // border-top: 1px solid $color;
  // border-right: 1px solid $color;
  // border-left: 1px solid $color;
}

.compare-nav {
  display: flex;
  border-radius: 10px 10px 0px 0px;
  font-size: 18px;
  font-weight: 600;

  .nav-link {
    position: relative;
    top: 2px;
    color: $black;
    // background-color: $orange-400;
    //@include border-for-nav-item($orange);
    // border-radius: 10px 10px 0px 0px;
    // &:hover {
    //   color: $black;
    //   background-color: $orange-300;
    // }
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    top: 2px;
    position: relative;
    color: $orange-an;
    border-bottom: 3px solid $orange-an;
    // background-color: $orange-200;
    // @include border-for-nav-item($orange-600);

    // &:hover {
    //   background-color: $orange-100;
    // }
  }
}
