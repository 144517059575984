.login {
  margin-top: 100px;

  .content-area {
    border-left: 10px solid #f1932d;
    background-color: white;
  }

  p {
    margin-bottom: 80px;
  }

  a {
    padding-top: 9px;
  }
}
