// Colours as recommended by Paul Tol (https://personal.sron.nl/~pault/)
// Discrete rainbow
$pink-graph: #d1bbd7;
$purple-graph: #ae76a3;
$indigo-graph: #882e72;
$blue-graph: #1965b0;
$orange-graph: #f1932d;
$red-graph: #dc050c;
$yellow-graph: #f7f056;
$teal-graph: #4eb265;
$green-graph: #90c987;
$cyan-graph: #7bafde;

// Colors from Allmännyttans graphical profile, with commented name given as per AN design
// Primary
// $orange-an: #de7c00; // Orange
// $orange-an: #de7c00; // Orange
$orange-an: #f86b32; // Orange
// Complementary
$aqua-an: #0097a9; // Aqua
$smaragd-an: #00a388; // Smaragd
$bla-an: #36749d; // Blå
$purpur-an: #b565a7; // Purpur
$rod-an: #c04c36; // Röd
$brun-an: #b47e00; // Brun
// Complementary with better contrast
$aqua-an-contrast: #00808f; // Aqua
$smaragd-an-contrast: #00856f; // Smaragd
$bla-an-contrast: #2c5f80; // Blå
$purpur-an-contrast: #945288; // Purpur
$rod-an-contrast: #9d3e2c; // Röd
$brun-an-contrast: #936700; // Brun

$card-background: #f3e9e0;

$grey-background: #f6f6f6;
