$width: 24px;

#progress {
  padding: 0;
  list-style-type: none;
  font-size: 18px;
  font-weight: 700;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
}

#progress li {
  display: flex;
  align-items: center;
  float: left;
  height: 50px;
  padding: 10px 10px 10px 40px;
  background: #ccc;
  color: #888;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 16%;
  margin: 0 1px;
}

#progress li:first-child {
  padding-left: 20px;
  color: white;
}

#progress li:not(:first-child):before {
  content: "";
  border-left: $width solid #fff;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

#progress li:after {
  content: "";
  border-left: $width solid #ccc;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
  background: #d5461d;
  color: white;
}

#progress li.done {
  background: #f86b32;
  color: white;
}

#progress li.active:after {
  border-left-color: #d5461d;
}

#progress li.done:after {
  border-left-color: #f86b32;
}
