.video {
  position: relative;
  width: 100%;
  // margin-top: 10px;
  // border: 1px solid #aaaaaa;
  video::-webkit-media-controls {
    display: none !important;
  }
  video {
    background: transparent;
    filter: brightness(0.9);
    cursor: pointer;
    display: block;
    max-width: 100%;
  }
  video.playing {
    filter: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100000000;
    background: rgba(0, 0, 0, 0.5);
    padding: 2vw;
  }
  .timer {
    height: 4px;
    position: absolute;
    bottom: 0px;
    // width: calc(100% - 31px);
    width: 100%;
  }
  .timer div {
    background-color: #ffad3c;
    height: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    z-index: 2;
  }
  .timer div:hover {
    animation: popUp 0.3s;
    animation-fill-mode: both;
  }
  #media-overlay {
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    transition: none;
  }
  #media-overlay.hidden {
    left: -9999px;
    right: 99999px;
    transition: left 0s 0.5s, right 0s 0.5s, background-color 0.3s;
  }
  #media-overlay.hidden .play-button {
    animation: popAway 0.5s 1;
  }
  #media-overlay .play-button-wrapper {
    position: absolute;
    left: 50%;
    top: 60%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
  .play-button {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    color: #fff;
    font-size: 22px;
    box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    white-space: nowrap;
    font-weight: 600;
    text-transform: uppercase;
    transition: all 100ms;
  }
  .play-button .triangle {
  }
  .play-button .triangle:after {
    border-bottom: 15px solid transparent;
    border-left: 25px solid #fff;
    border-top: 15px solid transparent;
    vertical-align: middle;
    margin-left: 10px;
    content: "";
    display: inline-block;
  }
  &:hover .play-button {
    transform: scale(1.1);
  }
  .play-button:hover {
    background: rgba(50, 50, 50, 0.6);
  }
}
