@import "./colors.scss";

g.row > * {
  width: auto;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

:root {
  accent-color: $orange-an;
  color-scheme: light;
}

a.button-rounded {
  width: 250px;
  background-color: #04aa6d !important;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: block;
  font-size: 14px;
  border-radius: 20px;
  color: $white;
  text-decoration: none;
  &:hover {
    color: $white;
    font-weight: bold;
  }
  &.active {
    font-weight: bold;
  }
  &.inactive {
    background-color: #f8faf1 !important;
    color: black;
    border: 2px solid #04aa6d !important;
  }
}

.navbar {
  .navbar-brand {
    font-size: 24px;
    font-weight: 900;
    @media only screen and (min-width: 1400px) {
      margin-right: 50px;
    }
  }
  .nav-link {
    color: rgba(0, 0, 0, 0.9) !important;
  }
}

h1,
h2 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  padding: 40px 0 20px 0;
}

h2 {
  font-size: 32px;
}

p {
  font-size: 24px;
}

p.small {
  font-size: 18px;
}

.btn-success {
  border-radius: 25px;
  height: 52px;
  background-color: #139a7e;
  border: 0;
  color: white;
  &:hover {
    color: white;
    background-color: #139a7e;
  }
}

$action-bg: #139a7e;

.btn-action {
  height: 52px;
  border-radius: 25px;
  padding: 0px 45px;
  font-size: 18px;
  @include button-variant(
    $action-bg,
    $action-bg,
    white,
    $action-bg,
    $action-bg,
    white
  );
  color: white;
  &:hover {
    color: white;
  }
}

.btn-hollow {
  height: 52px;
  border-radius: 25px;
  padding: 0px 45px;
  font-size: 18px;
  @include button-variant(
    transparent,
    $action-bg,
    #2c2c2c,
    transparent,
    $action-bg,
    #2c2c2c
  );
  color: #2c2c2c;
  &:hover {
    color: #2c2c2c;
  }
}

.route-page {
  background-color: $grey-background;
  min-height: 90vh;
}
