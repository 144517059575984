.organizationInfoPage {
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
  h3 {
    font-size: 24px;
    margin: 20px 0 0 0;
    font-weight: 700;
    border-bottom: 1px solid #eeeeee;
  }
  a {
    text-decoration: none;
    color: black;
    display: block;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    background-color: #f3e9e0;
    margin: 0;
    padding: 20px;
    .label {
      font-weight: bold;
      width: 250px;
    }
  }
}
