.radar-circle {
  fill: none;
  stroke: grey;
  stroke-width: 1;
}

.radar-axis {
  stroke: grey;
  stroke-width: 1;
}

.quadrant {
  font-size: 13px;
  font-weight: bold;
}

.ability {
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  fill: white;
}

.areaTitle {
  font-size: 24px;
  font-weight: bold;
}

.text-item {
  font-size: 16px;
}

.state-title {
  font-size: 14px;
}

div.dr-tooltip {
  position: absolute;
  text-align: left;
  width: 300px;
  padding-bottom: 10px;
  line-height: 13px;
  background: white;
  font: 16px proxima-nova;
  border: 0px;
  cursor: pointer;
}

// .levelsText {
//   text {
//   }
// }
