.abilitiesChart {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #f3e9e0;
  .ability-title-wrapper {
    width: 80%;
    margin-bottom: 14px;
    .ability-title {
      font-size: 24px;
      font-weight: 700;
    }
  }
  .recharts-legend-item-text {
    font-size: 16px;
  }
  .myStateTitle {
    margin-left: -15px;
    font-size: 16px;
    font-weight: 600;
  }
  .myCooperation {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
  }
  .ownState {
    .state {
      width: 30px;
      height: 30px;
      margin: 10px 0;
      &:first-child {
        margin: 5px 0;
      }
      &.aNoPriority {
        background-color: #1598aa;
      }
      &.aNotStarted {
        background-color: #aa5a9f;
      }
      &.aStarted {
        background-color: #b48128;
      }
      &.aUsingImprovements {
        background-color: #2a6a95;
      }
      &.aUsingAsIntended {
        background-color: #56bba2;
      }
    }
    a {
      img {
        display: block;
        height: 30px;
        margin: 5px 0 5px 50px;
      }
    }
    span {
      margin: 10px 0 10px 10px;
      font-size: 16px;
    }
  }
  .recharts-legend-wrapper {
    left: 80px !important;
  }
  .recharts-legend-item-text {
    color: rgb(51, 51, 51) !important;
  }
  .recharts-text {
    fill: rgb(51, 51, 51);
  }
}
