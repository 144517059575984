.about {
  p.paragraph-big {
    font-size: 24px;
  }
  p {
    font-size: 18px;
  }
  ul {
    li {
      font-size: 18px;
    }
  }
}
