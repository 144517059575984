.polarChart {
  .custom-tooltip {
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    max-width: 500px;

    .label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .desc {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }
  .recharts-default-legend {
    margin-left: 100px !important;
  }
  .recharts-legend-item-text {
    color: black !important;
  }
}
